<template>
  <div id="app" style="padding-top: 12px; padding-bottom: 3vw;">

    <!-- 用户 -->
    <div class="white_box3 mgt_3vw">
      <div class="white_box3_in pdb_0">
        <div class="user pd_3p6_0 clear" v-if="customerUser.businessCustomerUser"
             @click="showBusinessCustomerUserSelectPopup = true">
          <div class="user_avatar larger" :style="{backgroundImage:`url(${img2})`}"></div>
          <div class="user_msg">
            <span>{{ customerUser.businessCustomerUser.name }}</span>
            <label>{{
                customerUser.businessCustomerUser.business.name
              }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
          </div>
          <div class="user_r">
            <img src="../../assets/images/user_change.png" alt=""> 切换用户
          </div>
        </div>
      </div>

    </div>

    <!--餐费余额-->
    <div class="white_box3 mgt_3vw">
      <div class="white_box3_in">

        <div style="text-align: center; padding-top: 24px; padding-bottom: 32px;">
          <div style="font-size: 14px; line-height: 14px;">{{ '餐费总余额(元)' }}</div>
          <div style="display: flex; margin-top: 16px; place-content: center; text-align: center;"
               @click="routeToTransactionLogList">
            <div style="color: #222222; font-size: 24px; line-height: 24px; font-weight: bold; margin-right: 4px;">
              {{ formatAmount(totalBalance) }}
            </div>
            <van-icon name="arrow" style="align-self: center;"/>
          </div>
          <div style="display: flex; margin-top: 24px;">
            <div style="flex: 1;">
              <div style="color: #888888; font-size: 12px; line-height: 12px;">零钱余额</div>
              <div style="color: #222222; margin-top: 6px; ">{{ formatAmount(totalFreedomBalance) }}</div>
            </div>
            <div style="width: 1px; background: #D1D1D1; margin: 8px 0;"></div>
            <div style="flex: 1;">
              <div style="color: #888888; font-size: 12px; line-height: 12px;">餐次余额</div>
              <div style="color: #222222; margin-top: 6px;">{{ formatAmount(totalProductBundleBalance) }}</div>
            </div>
            <div style="width: 1px; background: #D1D1D1; margin: 8px 0;"></div>
            <div style="flex: 1;">
              <div style="color: #888888; font-size: 12px; line-height: 12px;">储值余额</div>
              <div style="color: #222222; margin-top: 6px;">{{ formatAmount(totalPrepaidCardBalance) }}</div>
            </div>
          </div>

          <!--          <div style="height: 1px; background: #F1F1F1; margin: 12px 4px;"></div>-->
        </div>

        <!-- 按钮 -->
        <div class="btn_two" style="padding-bottom: 8px;">
          <ul class="clear">
            <li>
              <button class="button_green" @click="routeToRechargeFreedom">零钱充值</button>
            </li>
            <li>
              <button class="button_orange" @click="routeToRechargeApplyList">餐次报名缴费</button>
            </li>
          </ul>
        </div>

        <!--        <button class="button_l_grey" @click="jumpCashout">提现</button>-->

      </div>
    </div>

    <!--余额变动明细-->
    <div class="white_box4 mgt_3vw">
      <van-cell title="余额变动明细" title-style="font-weight: bold;" is-link value="全部" @click="routeToTransactionLogList"
                :clickable="false"/>
      <div class="screen_list" v-if="transactionLogList && transactionLogList.length>0">
        <ul>
          <li v-for="(option,index) in transactionLogList" :key="index" @click="routeToFlowDetail(option)">
              <span><b style="color: #222222">{{
                  getTransactionDesc(option)
                }}</b> {{ option.productBundle ? option.productBundle.name : '' }} {{
                  option.purchase ? option.purchase.code : ''
                }}</span>
            <label v-if="option.purchase && option.type == 'REFUND'" style="color: #A1A1A1">{{
                option.createdTime |
                    dayjs
              }}</label>
            <label v-if="option.purchase && option.type !== 'REFUND'" style="color: #A1A1A1">{{
                option.purchase.consumeTime |
                    dayjs
              }}</label>
            <label v-if="!option.purchase">{{ option.createdTime | dayjs }}</label>
            <div v-if="option.businessId==='017b972ae06b003b2c96c1f37b970f88' && option.remark === '储值卡消费'">
              红包消费
            </div>
            <em v-html="getSymbol(option)"></em>
          </li>
        </ul>
        <div style="font-size: 12px; color: #D1D1D1; text-align: center; margin-bottom: 3vw;">
          仅显示前三条明细
        </div>
      </div>

      <van-empty v-if="!transactionLogList || transactionLogList.length===0"
                 description="暂无数据"/>
    </div>

    <!-- 选择用户 -->
    <van-popup v-model="showBusinessCustomerUserSelectPopup">
      <div class="white_box3_in pdb_0">
        <div class="sub_title2">选择用户</div>
        <!-- 用户 -->
        <div v-for="(option,index) in customerUserOptions" :key="index"
             class="user under_line pd_3p6_0 clear">
          <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
          <div class="user_msg">
            <span>{{ option.businessCustomerUser.name }}</span>
            <label>{{ option.businessCustomerUser.business.name }}，{{
                option.businessCustomerUser.structure.name
              }}</label>
          </div>
          <div class="user_r2 ico iconfont">
            <button class="button_green short" @click="switchUser(option)">选择</button>
          </div>
        </div>
      </div>
    </van-popup>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

  </div>
</template>


<style scoped>
.van-popup--center {
  width: 90vw;
}

::v-deep .van-cell.padd_8px_0 {
  padding: 8px 0 !important;
}

::v-deep .van-field__label {
  height: 34px;
  line-height: 34px;
}

::v-deep .van-field__value {
  height: 34px;
  line-height: 34px;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import Notify from "vant/es/notify";
import {formatAmount} from "../../utils/util";

// 注意，是查看消费者的钱包，而不是客户的钱包
export default {
  data() {
    return {
      img: require('../../assets/images/avatar.png'),
      img2: require('../../assets/images/avatar2.png'),
      showBalance: false,

      loading: false,
      customerUserOptions: [],
      customerUser: {},
      currentCustomerUser: {},
      customerAccount: {},

      cardBalance: 0,
      showCardBalance: false,
      paymentInfo: null,

      totalBalance: 0,
      totalFreedomBalance: 0,
      totalProductBundleBalance: 0,
      totalPrepaidCardBalance: 0,

      bundleCustomerUserAccountOptions: [], // PRODUCT_BUNDLE
      transactionLogList: [],

      showBusinessCustomerUserSelectPopup: false,
    };
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.currentCustomerUser = Vue.ls.get(ACCESS_CustomerUser)
    this.switchUser(this.currentCustomerUser);
  },
  methods: {
    goBack() {
      this.$router.push('/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    // 注意，这个只是当前页面切换，并不是切换当前用户
    switchUser(data) {
      if (!data) return;
      this.customerUser = data;
      this.showBusinessCustomerUserSelectPopup = false;

      this.totalBalance = 0;
      this.totalFreedomBalance = 0;
      this.totalProductBundleBalance = 0;
      this.totalPrepaidCardBalance = 0;
      this.transactionLogList = [];

      this.loading = true
      post('api/customer/user/v2/search', {}).then(res => {
        let isValid = false;
        for (let i = 0; i < res.data.rows.length; i++) {
          const item = res.data.rows[i];
          if (item.id === this.currentCustomerUser.id) {
            isValid = true;
            break;
          }
        }

        if (!isValid) {
          this.loading = false
          Notify({type: 'warning', message: '消费者绑定异常'});
          this.$router.push({path: '/index'});
          return;
        }

        this.customerUserOptions = res.data.rows

        this.fetchBalance();
        this.fetchBusinessPaymentInfo();
        this.fetchTransactionLogLast3();

      }).catch(() => this.loading = false)
    },
    jumpCashout() {
      if (!this.paymentInfo) {
        return;
      }
      if (this.paymentInfo.needPassword === '0') {
        this.$router.push({path: '/cashout'});
        return;
      }

      this.loading = true;
      post('api/customer/account/v2/:businessCustomerUserId/find'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}, true).then(res => {
        this.customerAccount = res.data

        if (!this.customerAccount || !this.customerAccount.id) {
          this.$router.push({path: '/AccountAgreement'});
          return;
        }
        // 根据不同平台进行判断
        if (this.customerAccount.auditStatus == 'YL' && this.paymentInfo.code === 'GF') {
          this.$router.push({path: '/AccountAgreement'});
          return;
        }

        if (!this.checkAccountState()) {
          return;
        }
        this.$router.push({path: '/cashout'})

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });

    },
    routeToRechargeApplyList() {
      this.$router.push({path: '/recharge/applyRechargeList'});
    },
    jumpRecharge() {
      if (!this.paymentInfo) {
        return;
      }
      if (this.paymentInfo.needPassword === '0') {
        this.$router.push({path: '/recharge'});
        return;
      }

      this.loading = true;
      post('api/customer/account/v2/:businessCustomerUserId/find'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}, true).then(res => {
        this.customerAccount = res.data;

        if (this.paymentInfo.code === 'GF') {
          if (!this.customerAccount.id) {
            this.$router.push({path: '/AccountAgreement'});
            return;
          } else if (!this.checkAccountState()) {
            return;
          }
        }

        // if (!this.customerAccount || !this.customerAccount.id
        //     || (this.customerAccount.auditStatus === 'YL' && this.paymentInfo.code === 'GF')) {
        //   this.$router.push({path: '/AccountAgreement'});
        //   return;
        // }
        //
        // if (!this.checkAccountState()) {
        //   return;
        // }
        this.$router.push({path: '/recharge'})

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });

    },
    checkAccountState() {
      if (this.customerAccount.auditStatus === '04') {
        // 二审失败
        this.$router.push({
          path: '/AccountCreateFeedback',
          query: {type: 'UPGRADE_FAIL', msg: this.customerAccount.auditRemark}
        });
        return false;

      } else if (this.customerAccount.auditStatus === '05') {
        // 等待影相再审核
        this.$router.push({path: '/AccountCreateFeedback', query: {type: 'ID_CARD_REVIEWING'}});
        return false;

      }
      return true;
    },
    // jumpCustomerUserAccount(data) {
    //   if (!this.customerAccount || !this.customerAccount.id) {
    //     this.$router.push({path: '/bankSwitch', query: {backUrl: 'accountDetail'}})
    //     return;
    //   }
    //
    //   this.$router.push({
    //     path: '/business_customer_user_free_amount',
    //     query: {businessCustomerUserId: data.businessCustomerUserId}
    //   })
    // },
    // jumpOldSystem() {
    //   window.location.href = 'https://wx.xzb360.com/xzb/home';
    // },
    getPrepaidCardBalance() {
      this.loading = true
      post('api//business/v2/' + this.customerUser.businessId + '/business/customer/user/' + this.customerUser.businessCustomerUserId + '/user/pocket/search', {
        filters: [{name: 'type', op: '=', value: 'PREPAID_CARD'}]
      }).then(res => {
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
          this.cardBalance = res.data.rows[0].balance
        } else {
          this.cardBalance = 0;
        }

        this.showCardBalance = true;
        this.showBalance = false;
        this.loading = false
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
        this.loading = false
      })
    },
    fetchBalance() {
      this.loading = true;

      this.totalBalance = 0;
      this.totalFreedomBalance = 0;
      this.totalProductBundleBalance = 0;
      this.totalPrepaidCardBalance = 0;

      post('api/business/v2/{businessId}/business/customer/user/{businessCustomerUserId}/user/pocket/search'
          .replace('{businessId}', this.customerUser.businessId)
          .replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId), {
        filters: []
      }).then(res => {
        this.totalBalance = 0;
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            let userPocket = res.data.rows[i];
            this.totalBalance += userPocket.balance;
            switch (userPocket.type) {
              case "PRODUCT_BUNDLE":
                this.totalProductBundleBalance += userPocket.balance;
                break;
              case "PREPAID_CARD":
                this.totalPrepaidCardBalance += userPocket.balance;
                break;
              case "FREEDOM":
                this.totalFreedomBalance += userPocket.balance;
                break;
            }
          }
        }
        if (this.totalBalance < 0) {
          Notify({type: 'warning', message: '余额显示异常，请联系客服处理，谢谢'});
        }

        this.showCardBalance = false;
        this.showBalance = true;
        this.loading = false
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
        this.loading = false
      })
    },
    fetchBusinessPaymentInfo() {
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            this.paymentInfo = result.data;
          })
          .catch(error => {
            console.log(error.message);
          })
    },

    fetchDetailList() {
      this.loading = true
      post('api/customer/user/v2/search', {
        filters: [{name: 'businessCustomerUserId', op: '=', value: this.customerUser.businessCustomerUserId}]
      }).then(res => {
        console.log("111111111")
        this.loadCustomerUserAccount(res.data.rows[0]);
        // this.loadBusinessPaymentInfo();
      }).catch(() => this.loading = false)
    },
    loadCustomerUserAccount(customerUser) {
      this.loading = true
      post('api/user/pocket/v2/search', {
        customerUserId: customerUser.id,
        businessCustomerUserId: this.customerUser.businessCustomerUserId
      }).then(res => {
        console.log(res)
        if (res.data) {
          res.data.forEach(item => {
            if (item.type === 'PRODUCT_BUNDLE') {
              this.bundleCustomerUserAccountOptions.push(item)
              // this.totalBundleBalance += item.balance;
            }
            if (item.type === 'FREEDOM') {
              if (item.balance) {
                // this.totalPrice += item.balance;
              }
            }
          })
        }
        this.loading = false
      }).catch(() => this.loading = false)
    },
    fetchTransactionLogLast3() {
      this.loading = true
      post('api/transaction/log/v2/search/owner', {
        filters: [
          {
            name: 'type',
            op: 'in',
            value: ['RECHARGE', 'DEDUCTION', 'WITHDRAW', 'REFUND', 'PARTIAL_REFUND']
          },
          {name: 'businessCustomerUserId', op: '=', value: this.customerUser.businessCustomerUserId},
          {name: 'state', op: '=', value: 'COMPLETE'},
        ],
        sort: 'createdTime desc',
        page: 0,
        size: 3
      }).then(res => {
        this.transactionLogList = res.data.rows
        this.loading = false
      }).catch(() => this.loading = false)
    },

    getTransactionDesc(data) {
      var type = data.type;
      // 显示补扣费逻辑，流水的创建时间-消费时间>60分钟
      if (type === 'RECHARGE') {
        return '充值';// 充值
      }
      if (type === 'DEDUCTION') {
        if (data.remark === '储值卡消费') return '红包';
        if (data.purchase) {
          if (this.difference(data.purchase.consumeTime, data.createdTime) > 60) {
            console.log(data.purchase.consumeTime, data.createdTime, this.difference(data.purchase.consumeTime, data.createdTime))
            return '补扣费';
          }
        }
        return '扣费';// 消费 补扣费 红包
      }
      if (type === 'WITHDRAW') {
        return '提现';// 提现
      }
      if (type === 'REFUND') {
        return '退款';// 退费
      }
      if (type === 'PARTIAL_REFUND') {
        return '部分退款';// 退费
      }
      return type;
    },
    getSymbol(data) {
      if (data.type === 'RECHARGE') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'DEDUCTION') return '<div style="font-weight: bold;" >-' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'WITHDRAW') return '<div style="font-weight: bold;" >-' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'REFUND') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'PARTIAL_REFUND') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      return '';
    },
    // 时间差计算
    difference(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      return dayDiff * 24 * 60 + hours * 60 + minutes;
    },

    routeToTransactionLogList() {
      this.$router.push({
        path: '/transaction_log',
        query: {businessCustomerUserId: this.customerUser.businessCustomerUserId}
      })
    },
    routeToFlowDetail(data) {
      this.$router.push({path: '/flowDetails', query: {id: data.id}})
    },
    routeToRechargeFreedom() {
      if (!this.paymentInfo) {
        return;
      }
      if (this.paymentInfo.needPassword === '0') {
        this.$router.push({
          path: '/recharge/freedom',
          query: {id: this.customerUser.id, businessCustomerUserId: this.customerUser.businessCustomerUserId}
        })
        return;
      }

      this.loading = true;
      post('api/customer/account/v2/:businessCustomerUserId/find'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}, true).then(res => {
        this.customerAccount = res.data;

        if (this.paymentInfo.code === 'GF') {
          if (!this.customerAccount.id) {
            this.$router.push({path: '/AccountAgreement'});
            return;
          } else if (!this.checkAccountState()) {
            return;
          }
        }

        this.$router.push({
          path: '/recharge/freedom',
          query: {id: this.customerUser.id, businessCustomerUserId: this.customerUser.businessCustomerUserId}
        })

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });

    },

    formatAmount(amount) {
      if (amount) {
        return formatAmount(amount);
      } else {
        return "0.00";
      }
    },
  }
}
;
</script>
